﻿.hero {
	position: absolute;
	top: 50%;
	left: rem(20px);
	transform: translateY(-50%);

	animation-duration: 2s;
	animation-delay: 0.5s;
	animation-fill-mode: backwards;
	animation-timing-function: ease-out;
	animation-name: slide-in;

	@include bp-min(m) {
		left: 50%;
		animation-name: slide-in-m;
	}

	.swiper-slide {
		opacity: 0 !important;
		//background: $color-primary;
	}

	.swiper-slide-active {
		opacity: 1 !important;
		//z-index: 10 !important;
	}
}

.hero-slide {
	color: #000;
	padding: 0 0 0 rem(28px);

	@include bp-min(s) {
		padding: 0 0 0 rem(68px);
	}

	.title {
		@include font-mega;
		color: #000;
	}

	.hero-slide-content {
		@include font-bigest;
		position: relative;
		padding: 0 0 0 rem(21px);
		margin: 0 0 rem(82px);

		p {
			margin: 0;
			font-weight: 300;
			font-style: italic;
		}

		&:before {
			position: absolute;
			content: '';
			height: calc(100% - 13px);
			width: 6px;
			top: rem(7px);
			left: rem(-16px);
			transform: skew(-11deg);
			background: #000;

			@include bp-min(s) {
				top: rem(19px);
				left: rem(-46px);
				height: calc(100% - 28px);
			}

			@include bp-min(l) {
				top: rem(19px);
				height: calc(100% - 28px);
			}
		}
	}

	.link {
		color: #000;
	}
}

.hero-wrapper {
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.hero-image {
	position: absolute;
	max-height: rem(1008px);
	bottom: 0;
	left: 0;
	opacity: 0.7;
	animation-name: hero-image;
	animation-duration: 6s;
	animation-timing-function: cubic-bezier(0,1,0.05,1);

	@include bp-min(m) {
		opacity: 1;
	}
}

@keyframes hero-image {

	from {
		bottom: -100%;
		left: -50%;
	}

	to {
		bottom: 0;
		left: 0;
	}
}

@keyframes slide-in-m {

	from {
		left: 100%;
	}

	to {
		left: 50%;
	}
}

@keyframes slide-in {

	from {
		left: 100%;
	}

	to {
		left: rem(20px);
	}
}