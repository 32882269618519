﻿.text {

	&.-bigger {
		@include font-medium;
	}

	p,
	ul,
	ol {
		margin: 0 0 rem(30px);

		&:last-child {
			margin: 0 0 rem(16px);
		}
	}

	ul:not(.social-media),
	ol {
		@extend .no-bullets;

		li {
			position: relative;
			padding: rem(6px) 0 rem(9px) rem(18px);

			&:before {
				position: absolute;
				content: '';
				width: rem(4px);
				height: rem(4px);
				border-radius: 50%;
				top: rem(20px);
				left: 0;
				background: $color-primary;
			}
		}
	}

	+ .buttons {
		margin-top: rem(27px)
	}

	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	&:last-child {

		p,
		ul,
		ol {

			&:last-child {
				margin: 0 0 rem(18px);
			}
		}
	}

	&.-last-no-margin {

		p,
		ul,
		ol,
		table {

			&:last-child {
				margin: 0;
			}
		}
	}

	table {
		display: inline-block;
		margin: 0;

		tr {
			/*display: block;
			margin: 0 0 rem(11px);

			@include bp-min(xs) {
				display: table-row;
				margin: initial;
			}*/
		}

		td {
			min-width: rem(210px);
			padding: 0 rem(10px) rem(13px) 0;

			&:last-child {
				padding-right: 0;
			}
		}

		p {
			margin: 0;
		}
	}
}
