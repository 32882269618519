﻿.slideshow {
	position: relative;
	padding: rem(93px) 0 rem(184px);
	z-index: 0;
	color: #000;

	.swiper-slide {
		opacity: 0 !important;
	}

	.swiper-slide-active {
		opacity: 1 !important;
	}

	.title {
		margin: 0 0 rem(43px);

		@include bp-min(m) {
			max-width: 40%;
		}
	}

	.link {
		color: #000;
	}

	.text {
		font-size: rem(18px);
		line-height: rem(38px);
	}

	.row {
		display: flex;
		flex-wrap: wrap;
	}

	&:before {
		position: absolute;
		z-index: -2;
		content: '';
		width: rem(150px);
		height: 100%;
		top: 0;
		right: calc(50% + 90px);
		background-image: linear-gradient(to right top, $color-primary 0%, $color-primary 50%, black 50%);
		background-size: 100% 100%;
		transform: scaleX(-1);
		display: none;

		@include bp-min(m) {
			display: block;
		}
	}

	&:after {
		position: absolute;
		z-index: -2;
		content: '';
		width: 100%;
		height: 60%;
		bottom: 0;
		right: 0;
		background: $color-primary;

		@include bp-min(m) {
			width: calc(50% + 90px);
			height: 100%;
			top: 0;
			bottom: auto;
		}
	}
}

.slideshow-item {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	background: $color-primary;

	> div {
		width: 100%;

		@include bp-min(m) {
			padding-left: rem(52px);
		}
	}

	@include bp-min(m) {
		background: none;
	}
}

.slideshow-spot {
	position: absolute;
	z-index: -1;
	overflow: hidden;
	content: '';
	width: rem(1920px);
	height: 100%;
	top: 0;
	right: 0;
	background: url(../../images/spot-right.png) no-repeat;
	background-position: right top;
	display: none;

	@include bp-min(m) {
		display: block;
	}
}