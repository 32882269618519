﻿[data-navigation],
.navigation {
	width: 100%;

	ul {
		@extend .clearfix;
		@extend .no-bullets;
	}

	@include bp-min(m) {
		float: left;
		width: auto;
		padding-left: rem(300px);
	}

	> ul {
		width: 100%;
		margin: 0;

		> li {
			position: relative;
			float: left;
			width: 100%;

			@include bp-min(m) {
				width: auto;
			}

			> a {
				display: block;
				padding: rem(10px) rem(14px) rem(10px);
				font-weight: 800;
				font-style: italic;
				text-transform: uppercase;
				color: #000;


				span {
					position: relative;

					&:before {
						position: absolute;
						content: '';
						width: 100%;
						height: 3px;
						bottom: -7px;
						left: 0;
						background: #000;
						opacity: 0;
						transition: all 0.4s ease-in-out;
					}
				}
			}

			&:hover,
			&:focus,
			&.active {

				a span:before {
					opacity: 1;
					bottom: -3px;
				}
			}
		}
	}
}
