﻿.legal {
	@include font-small;
	position: relative;
	padding: 0;
	margin: 0;
	overflow: hidden;
	color: #000;

	@include bp-min(m) {
		width: (100% / 3 * 2);
	}

	@include bp-min(l) {
		padding: 0;
	}

	&__list {
		overflow: hidden;
		list-style: none;
		margin: 0;
		padding: rem(9px) 0 rem(7px);

		@include bp-min(m) {
			display: flex;
			flex-wrap: wrap;
		}
	}

	li {
		float: left;
		padding-top: rem(4px);
		padding-bottom: rem(4px);
		margin-right: rem(20px);
		text-transform: none;

		&.-panorama {
			margin-left: auto;
		}
	}

	a {
		text-decoration: none;
		color: inherit;

		&:hover {
			text-decoration: underline;
		}
	}
}
