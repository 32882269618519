﻿.route-form {
	z-index: 10;
	width: 100%;
	top: rem(40px);
	max-width: rem(1550px);
	padding: 0 rem(20px) rem(20px);

	@include bp-min(s) {
		position: absolute;
		transform: translateX(-50%);
		left: 50%;
	}

	@include bp-min(xl) {
		padding: 0;
	}

	.input-group {
		margin: 0;
		display: block;

		@include bp-min(s) {
			display: flex;
		}
	}

	input {
		width: 100%;
		border: none;
		margin-bottom: rem(20px);

		@include bp-min(s) {
			margin-bottom: 0;
		}
	}

	.button__wrapper {
		flex-grow: 0;
		flex-shrink: 0;
		margin-bottom: rem(20px);

		@include bp-min(s) {
			display: flex;
			margin-bottom: 0;
		}
	}

	button {
		background: #fff;
		color: #000;
		border: none;
		padding-right: rem(20px);
		padding-left: rem(20px);

		@include bp-min(m) {
			padding-left: rem(36px);
		}

		&.link.-arrow {

			&:before {
				top: rem(16px);
				//left: rem(20px);
				display: none;

				@include bp-min(s) {
					display: block;
				}
			}

			&:after {
				top: rem(24px);
				display: none;

				@include bp-min(m) {
					display: block;
				}
			}

			&:hover {
				padding-right: rem(20px);
				padding-left: rem(20px);

				@include bp-min(m) {
					padding-left: rem(36px);
					padding-right: rem(20px);
				}
			}
		}
	}
}
