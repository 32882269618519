﻿.image {

	&.-fill-container {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;

		&.-top-left {
			object-position: top left;
		}

		&.-top-right {
			object-position: top right;
		}
	}
}

.image-container {
	@include responsive-container-psudo(474, 316);
	position: relative;
	margin: 0 0 rem(39px);
	float: left;
	width: 100%;

	@include bp-min(m) {
		max-width: 474px;
		margin: 0 rem(105px) rem(40px) 0;
	}

	&.-right {

		@include bp-min(m) {
			float: right;
			order: 1;
			margin: 0 0 rem(40px) rem(105px);
		}
	}

	&.-medium {
		@include responsive-container-psudo(756, 504);
		width: 100%;

		@include bp-min(l) {
			max-width: 756px;
		}
	}

	&.-large {
		margin: 0 0 rem(45px);
		max-width: inherit;
		//order: 1;
		.image {
			height: 100%;
		}

		@include bp-min(m) {
			@include responsive-container-psudo(1280, 638);
			min-height: rem(638px);
			width: 50%;
			margin: 0;
			//order: 0;
		}
	}

	&.instagram {
		@include responsive-container-psudo(600, 600);
		margin: 0 0 rem(40px);
		overflow: hidden;

		figcaption {
			position: absolute;
			padding: rem(20px) rem(40px);
			background: rgba(#000, 0.8);
			bottom: 0;
			left: 0;
		}
	}
}