﻿.title {
	@include font-large;
	font-weight: 800;
	font-style: italic;
	color: $color-primary;
	margin: 0;
	text-transform: uppercase;

	&.-margin {
		margin: 0 0 rem(33px);
	}

	&.-small {
		@include font-medium;
	}

	.border {
		position: relative;
		padding: 0 0 0 rem(17px);
		display: block;

		@include bp-min(s) {
			padding: 0 0 0 rem(30px);
		}

		&:before {
			position: absolute;
			content: '';
			height: calc(100% - 8px);
			width: 3px;
			top: rem(4px);
			left: 0;
			transform: skew(-11deg);
			background: $color-primary;

			@include bp-min(s) {
				top: rem(6px);
				height: calc(100% - 12px);
			}

			@include bp-min(l) {
				top: rem(5px);
				height: calc(100% - 13px);
			}
		}

		p {
			margin: 0;
		}
	}
}
