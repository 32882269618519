﻿.contact-information {
	overflow: hidden;
	margin: 0;

	dt {
		float: left;
		width: rem(36px);
	}

	dd {
		float: left;
		width: calc(100% - 36px);
		margin: 0;
	}
}
