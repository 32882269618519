﻿.banners {
	margin-top: rem(90px);
	margin-right: rem(-45px);
	margin-bottom: rem(45px);
	margin-left: rem(-45px);

	.col {
		border-right-width: rem(45px);
		border-left-width: rem(45px);
	}
}

.banner {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	background: $color-primary;
	color: #000;
	padding: rem(43px) rem(48px) rem(41px);
	margin-bottom: rem(45px);
	min-height: rem(313px);

	@extend .spot;

	.title {
		color: #000;
		margin: 0 0 rem(15px);

		.border:before {
			background: #000;
		}
	}

	.text {
		font-style: italic;
	}

	.link {
		margin-top: auto;
		color: #000;

		&:after {
			background: #000;
		}
	}
}
