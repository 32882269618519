﻿label {
	@include font-smaller;
	//font-size: 75%;
	margin-bottom: 0;
	color: #000;
}

select,
textarea,
input,
.readonly {
	outline: none;
	margin-bottom: 0.5rem;

	&:invalid {
		box-shadow: none;
	}

	&:focus {
		// TODO: Fix textarea sizing when increasing padding/border
		//padding-top: 0.425rem;
		//border-bottom-width: 0.1rem;
		border-bottom-color: #000;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;

		&:invalid {
			border-bottom-color: $color-primary;
		}
	}
}

::placeholder {
	//font-size: 75%;
}

.input-group-addon {
	padding-left: 1rem;
}

.form-actions {
	padding: 0;
}

.validation-summary-errors ul {
	@extend .no-bullets;
}

.help-text {
	background-color: $alert-background-color;
	padding: 0.5rem 1rem;
}

.umbraco-forms-field.email2 {
	display: none;
}

.umbraco-forms-field {
	// Full height textarea
	&.longanswer:only-child {
		display: flex;
		flex-direction: column;
		height: 100%;

		textarea {
			flex-grow: 1;
		}
	}
	// Floating labels
	.js & {
		::placeholder {
			opacity: 0;
			transition: opacity 0.2s ease-out;
		}

		label {
			position: relative;
			top: rem(40px);
			left: 0.5rem;
			z-index: 2;
			transition: all 0.2s ease-out;
		}

		&.has-value,
		&.focus {

			::placeholder {
				opacity: 1;
			}

			label {
				top: 0;
				left: 0;
				color: #fff;
				font-size: 75%;
			}
		}
	}
	// Icons
	&.bedrijfsnaam label:before,
	&.companyname label:before {
		@include icon($icon-building);
		margin: 0 rem(22px);
		font-size: rem(20px);
	}

	&.contactpersoon label:before,
	&.contact label:before {
		@include icon($icon-person);
		margin: 0 rem(22px);
		font-size: rem(17px);
	}

	&.telefoonnummer label:before,
	&.telephonenumber label:before {
		@include icon($icon-phone);
		margin: 0 rem(22px);
		font-size: rem(20px);
	}

	&.emailadres label:before,
	&.emailaddress label:before {
		@include icon($icon-mail);
		margin: 0 rem(22px);
		font-size: rem(20px);
		top: rem(3px);
		position: relative;
	}

	&.bericht label:before,
	&.message label:before {
		@include icon($icon-balloon);
		margin: 0 rem(22px);
		font-size: rem(20px);
	}
}
