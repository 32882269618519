﻿.languages {
	max-width: rem(1240px);
	width: 100%;
	padding: rem(15px) rem(20px);
	margin: 0 auto rem(150px);
	color: #000;
	font-weight: 800;
	font-style: italic;

	&__list {
		padding: 0;
		margin: 0 rem(-20px);
		list-style: none;
		overflow: hidden;
	}

	dt {
		display: none;
	}

	dd {
		margin: 0;
		padding: 0;
	}

	li {
		width: 100%;

		@include bp-min(m) {
			float: left;
			width: auto;
		}

		a {
			position: relative;
			display: block;
			padding: rem(9px) rem(12px);

			@include bp-min(m) {
				padding: rem(15px) rem(22px);
			}

			span {
				position: relative;

				&:before {
					position: absolute;
					content: '';
					width: 100%;
					height: 3px;
					bottom: -7px;
					left: 0;
					background: $color-primary;
					opacity: 0;
					transition: all 0.4s ease-in-out;
				}
			}
		}

		&:hover span:before {
			bottom: -3px;
			opacity: 1;
		}
	}

	@include bp-min(m) {
		float: right;
		width: auto;
		padding: 0;
		margin: 0;

		dt {
			display: block;
			position: relative;
			padding: rem(9px) rem(20px) rem(10px) rem(29px);
			text-transform: uppercase;
			cursor: pointer;

			&:before {
				position: absolute;
				content: '';
				height: calc(100% - 31px);
				width: 3px;
				top: rem(17px);
				left: rem(18px);
				transform: skew(-11deg);
				background: #000;
			}
		}

		dd {
			position: absolute;
			right: 0;
			opacity: 0;
			visibility: hidden;
			background: #080402;
			padding: rem(5px) 0 rem(6px);
		}

		&:hover {
			background: #080402;

			dt {
				color: $color-primary;
			}

			dd {
				opacity: 1;
				visibility: visible;
			}
		}

		&__list {
			list-style: none;
			padding: 0;
			margin: 0;

			li {
				position: relative;
				float: left;
				clear: both;

				a {
					display: block;
					padding: rem(4px) rem(10px) rem(5px);
					transition: color 0.3s ease-in-out;
					color: $color-primary;
				}
			}
		}
	}
}
