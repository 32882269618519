﻿.link {
	@include font-medium;
	position: relative;
	font-weight: 800;
	font-style: italic;
	color: $color-primary;
	padding: 0 0 0 rem(28px);

	&.-arrow {
		transition: all 0.2s ease-in-out;

		&:before {
			@include icon($icon-arrow-right);
			position: absolute;
			top: rem(3px);
			left: 0;
			font-size: rem(19px);
			transition: left 0.2s ease-in-out;
		}

		&:after {
			position: absolute;
			content: '';
			height: rem(3px);
			width: 1px;
			top: rem(11px);
			left: 0;
			background: $color-primary;
			transition: all 0.2s ease-in-out;
		}

		&.-back {

			&:before {
				@include icon($icon-arrow-left);
			}

			&:after {
				left: rem(18px);
			}

			&:hover {

				&:before {
					left: rem(0);
				}
			}
		}

		&:hover {
			padding: 0 0 0 rem(38px);


			&:before {
				left: rem(10px);
			}

			&:after {
				width: rem(11px);
			}
		}

		&.-black {
			color: #000;

			&:after {
				background: #000;
			}
		}
	}
}
