﻿.intro {
	position: relative;
	padding: 0 0 0 rem(28px);
	margin: 0 0 0;

	&:before {
		position: absolute;
		content: '';
		height: calc(100% - 16px);
		width: 3px;
		top: rem(7px);
		left: rem(11px);
		transform: skew(-11deg);
		background: $color-primary;
	}
}