﻿@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat-LightItalic.woff2') format('woff2'), url('../../fonts/Montserrat-LightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
	font-stretch: normal;
	unicode-range: U+0002-FFFD;
	font-display: block;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat-Regular.woff2') format('woff2'), url('../../fonts/Montserrat-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0002-FFFD;
	font-display: block;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat-Italic.woff2') format('woff2'), url('../../fonts/Montserrat-Italic.woff') format('woff');
	font-weight: 400;
	font-style: italic;
	font-stretch: normal;
	unicode-range: U+0002-FFFD;
	font-display: block;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat-MediumItalic.woff2') format('woff2'), url('../../fonts/Montserrat-MediumItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
	font-stretch: normal;
	unicode-range: U+0002-FFFD;
	font-display: block;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat-BoldItalic.woff2') format('woff2'), url('../../fonts/Montserrat-BoldItalic.woff') format('woff');
	font-weight: 700;
	font-style: italic;
	font-stretch: normal;
	unicode-range: U+0002-FFFD;
	font-display: block;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat-ExtraBold.woff2') format('woff2'), url('../../fonts/Montserrat-ExtraBold.woff') format('woff');
	font-weight: 800;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0002-FFFD;
	font-display: block;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat-ExtraBoldItalic.woff2') format('woff2'), url('../../fonts/Montserrat-ExtraBoldItalic.woff') format('woff');
	font-weight: 800;
	font-style: italic;
	font-stretch: normal;
	unicode-range: U+0002-FFFD;
	font-display: block;
}

@mixin font-text {
	font-family: $base-font-family;
	font-size: rem(20px);
	line-height: rem(30px);

	@media only screen and (min-width: 432px) {
		font-size: rem(19px);
	}

	@media only screen and (min-width: 600px) {
		font-size: rem(18px);
	}
}

@mixin font-small {
	font-size: rem(12px);
	line-height: rem(30px);
}

@mixin font-smaller {
	font-size: rem(16px);
	line-height: rem(30px);
}

@mixin font-medium {
	font-size: rem(20px);
	line-height: rem(30px);
}

@mixin font-bigger {
	font-size: rem(24px);
	line-height: rem(30px);
}

@mixin font-big {
	font-size: rem(30px);
	line-height: rem(38px);
}

@mixin font-bigest {
	font-size: rem(30px);
	line-height: rem(40px);
}

@mixin font-large {
	font-size: rem(30px);
	line-height: rem(30px);

	@include bp-min(s) {
		font-size: rem(40px);
		line-height: rem(40px);
	}

	@include bp-min(l) {
		font-size: rem(50px);
		line-height: rem(50px);
	}
}

@mixin font-huge {
	font-size: rem(40px);
	line-height: rem(40px);

	@include bp-min(s) {
		font-size: rem(50px);
		line-height: rem(50px);
	}

	@include bp-min(m) {
		font-size: rem(60px);
		line-height: rem(70px);
	}

	@include bp-min(l) {
		font-size: rem(80px);
		line-height: rem(100px);
	}
}

@mixin font-huger {
	font-size: rem(40px);
	line-height: rem(40px);

	@include bp-min(s) {
		font-size: rem(50px);
		line-height: rem(50px);
	}

	@include bp-min(m) {
		font-size: rem(70px);
		line-height: rem(70px);
	}

	@include bp-min(l) {
		font-size: rem(90px);
		line-height: rem(100px);
	}
}

@mixin font-mega {
	font-size: rem(40px);
	line-height: rem(40px);

	@include bp-min(s) {
		font-size: rem(60px);
		line-height: rem(60px);
	}

	@include bp-min(m) {
		font-size: rem(80px);
		line-height: rem(80px);
	}

	@include bp-min(l) {
		font-size: rem(120px);
		line-height: rem(120px);
	}
}
