﻿.content-block {
	position: relative;
	overflow: hidden;
	display: flex;
	flex-wrap: wrap;

	+ .content-block {
		border-top: 1px solid rgba(#fff, 0.2);
	}
}

.text-container {

	@include bp-min(m) {
		float: left;
		width: 50%;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
}

.text-content {
	width: 100%;
	padding-top: rem(83px);
	padding-bottom: rem(63px);
}

.image-container.-large.-left + .text-container .text-content {
	padding: 0 rem(20px) rem(81px);

	@include bp-min(m) {
		max-width: rem(775px);
		padding-right: rem(20px);
		padding-left: rem(68px);
	}

	@include bp-min(xl) {
		padding-left: rem(98px);
	}
}

.image-container.-large.-right + .text-container .text-content {
	padding: 0 rem(20px) rem(81px);

	@include bp-min(m) {
		max-width: rem(775px);
		padding-right: rem(68px);
		padding-left: rem(20px);
		margin-left: auto;
	}

	@include bp-min(xl) {
		padding-right: rem(98px);
	}
}