﻿.swiper-pagination,
.slideshow-swiper-pagination {
	margin: 0 rem(-12px);

	.swiper-pagination-bullet {
		@include font-medium;
		position: relative;
		width: auto;
		height: auto;
		background: none;
		color: #000;
		font-weight: 800;
		font-style: italic;
		text-transform: uppercase;
		margin: 0 rem(12px);
		opacity: 1;

		&:after {
			position: absolute;
			content: '';
			width: 100%;
			height: 3px;
			bottom: 0;
			left: 0;
			background: #000;
			opacity: 0;
			transition: opacity 0.4s ease-in-out;
		}
	}

	.swiper-pagination-bullet-active {

		&:after {
			opacity: 1;
		}
	}
}

.swiper-pagination {
	position: absolute;
	left: 50%;
	bottom: rem(41px);
	transform: translateX(-50%);
	width: 100%;
}

.slideshow-swiper-pagination {
	margin: rem(32px) rem(-12px) rem(32px);

	.swiper-pagination-bullet {
		@include font-bigger;
	}

	@include bp-min(m) {
		margin: 0 rem(-12px) rem(32px);
	}
}
