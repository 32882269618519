﻿$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-width: 32px;
$hamburger-layer-height: 3px;
$hamburger-layer-spacing: 6px;
$hamburger-layer-color: #000;
$hamburger-layer-border-radius: 0;
$hamburger-hover-opacity: 1;
$hamburger-hover-transition-duration: 0.3s;
$hamburger-hover-transition-timing-function: ease-in-out;

@import "~hamburgers/_sass/hamburgers/hamburgers";

.hamburger {
	display: block;
	z-index: 1001;
	position: fixed;
	height: 58px;
	width: 58px;
	padding: 0 10px;
	left: 50%;
	bottom: 40px;
	border: 1px solid rgba(#000, 0.1);
	cursor: pointer;
	background: $color-primary;
	color: #fff;
	opacity: 1;
	transition: all 0.3s ease-in-out;
	font-weight: 400;
	transform: translateX(-50%) skew(-11deg);

	@include bp-min(m) {
		display: none;
	}

	.hamburger-box {
		top: 5px;
	}

	&:hover {
		background: $color-primary;
	}
}