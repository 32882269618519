﻿.spot {
	position: relative;

	&:before {
		position: absolute;
		z-index: 0;
		overflow: hidden;
		content: '';
		width: rem(954px);
		height: 100%;
		top: 0;
		left: 0;
		background: url(../../images/spot.png) no-repeat;
		background-position: 0 0;
	}
}
