﻿.google-maps {
	position: relative;
	width: 100%;
	height: rem(552px);
	z-index: 1;

	&:last-child {
		margin: 0 0 rem(80px);
	}

	&__wrapper {
		position: relative;
		width: 100%;
		height: rem(552px);
	}
}
