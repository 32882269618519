﻿.logo {
	z-index: 101;
	display: block;
	width: rem(250px);
	height: rem(103px);
	text-indent: -9999px;
	background: url(../../images/logo-witkamp.svg) no-repeat center;
}

.logo-footer {
	z-index: 101;
	display: block;
	width: rem(200px);
	height: rem(186px);
	text-indent: -9999px;
	background: url(../../images/logo-witkamp-footer.svg) no-repeat center;

	@include bp-min(m) {
		margin: 0 auto;
	}
}