﻿.container {
	padding: 0 rem(20px);
	width: 100%;

	&.-relative {
		position: relative;
	}

	&.-center {
		text-align: center;
	}
}
