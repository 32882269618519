﻿.social-media {
	@extend .no-bullets;
	line-height: 1;
	width: 100%;
	overflow: hidden;
	margin: 0 !important;

	@include bp-min(m) {
		width: auto;
		float: left;
	}

	li {
		@extend .pull;
		margin: 0 rem(5px);
	}

	a {
		position: relative;
		z-index: 0;
		display: block;
		text-decoration: none;
		transition: color 0.25s ease-in-out;
		text-decoration: none;
		font-size: rem(21px);
		width: rem(44px);
		height: rem(44px);
		color: #000;
		text-align: center;
		padding-top: rem(11px);

		&:before {
			position: absolute;
			z-index: -1;
			content: '';
			background: $color-primary;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			transform: skew(-11deg);
			transition: background 0.4s ease-in-out;
		}

		&:focus,
		&:hover {
			text-decoration: none;
			//color: $color-primary-light;
			outline: none;

			&:before {
				background: darken($color-primary, 10%);
			}
		}
	}
}
