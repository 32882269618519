﻿$icon-arrow-down: "\ea01";
$icon-arrow-left: "\ea02";
$icon-arrow-right: "\ea03";
$icon-arrow-up: "\ea04";
$icon-balloon: "\ea05";
$icon-building: "\ea06";
$icon-facebook: "\ea07";
$icon-instagram: "\ea08";
$icon-pinterest: "\ea09";
$icon-twitter: "\ea0a";
$icon-youtube: "\ea0b";
$icon-mail: "\ea0c";
$icon-person: "\ea0d";
$icon-phone: "\ea0e";

@at-root {
    @font-face {
        font-family: icon;
        
        src: url("../../fonts/icon.woff2") format("woff2"), url("../../fonts/icon.woff") format("woff"); 
        font-style: normal;
        font-weight: normal;
    }
}

@mixin icon($content: none) {
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    font-family: icon;
    line-height: 1;
    text-transform: none;
    speak: none;
    content: $content;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon:before {
    @include icon;
}

.icon-arrow-down:before { content: $icon-arrow-down; }
.icon-arrow-left:before { content: $icon-arrow-left; }
.icon-arrow-right:before { content: $icon-arrow-right; }
.icon-arrow-up:before { content: $icon-arrow-up; }
.icon-balloon:before { content: $icon-balloon; }
.icon-building:before { content: $icon-building; }
.icon-facebook:before { content: $icon-facebook; }
.icon-instagram:before { content: $icon-instagram; }
.icon-pinterest:before { content: $icon-pinterest; }
.icon-twitter:before { content: $icon-twitter; }
.icon-youtube:before { content: $icon-youtube; }
.icon-mail:before { content: $icon-mail; }
.icon-person:before { content: $icon-person; }
.icon-phone:before { content: $icon-phone; }
