﻿.members {
	padding: rem(74px) 0 0;

	.title {
		margin: 0 0 rem(36px);
	}

	.row {
		display: flex;
		flex-wrap: wrap;
	}
}

.member {
	margin: 0 0 rem(72px);

	.member-title {
		position: relative;
		color: $color-primary;
		font-style: italic;
		padding-left: rem(15px);
		margin: 0 0 rem(28px);

		h3 {
			@include font-bigger;
			text-transform: uppercase;
			font-weight: 800;
			margin: 0;
		}

		&:before {
			position: absolute;
			content: '';
			height: calc(100% - 16px);
			width: 3px;
			top: rem(7px);
			left: 0;
			transform: skew(-11deg);
			background: $color-primary;
		}
	}

	.image {
		border-bottom: 1px solid rgba(#fff, 0.1);
		margin-bottom: rem(24px);
	}

	.member-image-container {
		margin: 0; 
	}
}
