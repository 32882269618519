﻿.responsive-nav {
	position: fixed;
	z-index: 1000;
	width: rem(310px);
	height: 100%;
	padding: rem(150px) 0 0;
	top: 0;
	right: 0;
	transition: all 0.3s ease-in-out;
	visibility: visible;
	opacity: 1;
	background: $color-primary;
	overflow-x: hidden;
	overflow-y: auto;

	@include bp-min(m) {
		position: absolute;
		width: auto;
		height: auto;
		padding: 0;
		top: 50%;
		right: rem(20px);
		transform: translateY(-50%);
		background: none;
		overflow: initial;
	}

	&.-collapsed {
		visibility: hidden;
		opacity: 0;

		@include bp-min(m) {
			visibility: visible;
			opacity: 1;
		}
	}

	&__inner {
		height: 100%;
	}

	.social-media {
		@extend .-center;
	}
}
