﻿.big-banner {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	color: $color-primary;

	.big-banner-container {
		display: flex;
		align-items: center;
		float: left;

		@include bp-min(m) {
			width: 50%;
		}
	}

	.big-banner-content {
		width: 100%;
		max-width: rem(755px);
		margin: 0 0 rem(81px);
		padding: 0 rem(20px);

		@include bp-min(m) {
			margin-bottom: 0;
			margin-left: auto;
			padding: 0 0 0 rem(20px);
		}
	}

	.big-banner-intro {
		@include font-big;
		position: relative;
		font-weight: 300;
		font-style: italic;
		padding: 0 0 0 rem(55px);
		margin: 0 0 rem(33px);

		p {
			margin: 0;
			font-weight: 300;
			font-style: italic;
			letter-spacing: 0.06em;
		}

		&:before {
			position: absolute;
			content: '';
			height: calc(100% - 15px);
			width: 4px;
			top: rem(7px);
			left: rem(3px);
			transform: skew(-11deg);
			background: $color-primary;

			@include bp-min(m) {
				height: calc(100% - 18px);
				top: rem(10px);
			}

			@include bp-min(l) {
				height: calc(100% - 26px);
				top: rem(18px);
			}
		}
	}

	.title {
		@include font-huger;
	}

	.link {
		margin-left: rem(40px);
	}
}
