﻿.footer-content {
	color: #000;
	padding: 0 0 rem(34px);

	.link {
		color: #000;
	}

	ul {
		@extend .no-bullets;
		margin: 0;
	}

	li {
		margin: 0 0 rem(20px);
	}

	p {
		margin: 0;
	}

	.footer-content-text {
		margin: 0 0 rem(28px);
	}

	.footer-content-contact {
		color: #000;

		@include bp-min(m) {
			padding: 0 0 0 rem(70px);
			color: #fff;
		}

		@include bp-min(xl) {
			padding: 0 0 0 rem(130px);
		}
	}

	.title {
		color: #000;

		@include bp-min(m) {
			color: $color-primary;
		}

		.border {
			padding: 0 0 0 rem(10px);
		}

		.border:before {
			width: 2px;
			height: calc(100% - 13px);
			top: rem(8px);
			background: #000;

			@include bp-min(m) {
				background: $color-primary;
			}
		}
	}
}
